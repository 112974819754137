import { useStaticQuery, graphql } from "gatsby"

export default () => {
  return useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            description
            siteLanguage
            siteUrl
            siteName
            phoneNumber
            phoneNumberDisplay
            logo
            currentYear
            reviews {
              text
              name
              img
            }
          }
        }
      }
    `
  )
}
