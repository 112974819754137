import React, { useState } from "react"
import ContactFormInput from "./ContactFormInput"
import ContactFormTextArea from "./ContactFormTextArea"
import Button from "./Button"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + `=` + encodeURIComponent(data[key]))
    .join(`&`)
}

const ContactForm = () => {
  const [state, setState] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleChange = ({ target }) =>
    setState({ ...state, [target.name]: target.value })

  const handleSubmit = event => {
    event.preventDefault()
    const form = event.target

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => setIsSubmitted(true))
      // eslint-disable-next-line no-console
      .catch(error => console.error(error))
  }

  return (
    <div className="flex flex-col md:px-8 md:w-1/2">
      {isSubmitted ? (
        <div className="text-center my-4">
          <div className="text-3xl mb-2">Thank You!</div>
          <div className="text-xl">
            The form was successfully submitted. We usually respond in less than
            24 hours.
          </div>
        </div>
      ) : (
        <>
          <div className="text-center my-4">
            <div className="text-3xl mb-2">Let&#39;s Chat!</div>
            <div className="text-xl">
              Find out how we can help YOU keep more money in your pocket.
            </div>
          </div>

          <form
            name="contact"
            method="post"
            action="/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
            className="flex flex-col"
          >
            <input type="hidden" name="form-name" value="contact" />
            <div hidden>
              <label>
                Don’t fill this out:{" "}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </div>

            <div className="mb-2">
              <ContactFormInput
                name="name"
                label="Your Name (required)"
                placeholder="John Doe"
                required={true}
                onChange={handleChange}
              />
              <ContactFormInput
                name="email"
                label="Your Email (required)"
                type="email"
                placeholder="john@doe.com"
                required={true}
                onChange={handleChange}
              />
              <ContactFormInput
                name="subject"
                label="Subject (required)"
                placeholder="Tax Return"
                required={true}
                onChange={handleChange}
              />
              <ContactFormTextArea
                name="message"
                label="Your Message (required)"
                required={true}
                placeholder="What is the last date for tax return in San Diego?"
                onChange={handleChange}
              />
            </div>

            <Button text="Submit" />
          </form>
        </>
      )}
    </div>
  )
}

export default ContactForm
