import React from "react"

const List = ({ items }) => (
  <ul className="list-inside list-disc text-xl mb-4">
    {items.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </ul>
)

export default List
