import React from "react"

const LeftIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    className="fill-current text-blue-600 hover:text-blue-500 transition-colors duration-150 ease-in-out"
  >
    <g>
      <path d="M49.132,21.984L12.714,48.039c-0.66,0.471-1.049,1.233-1.051,2.043c0,0.006,0,0.006,0,0.006   c0.002,0.815,0.396,1.575,1.059,2.048L49.14,78.023c0.767,0.546,1.776,0.616,2.612,0.183c0.835-0.426,1.361-1.291,1.361-2.236   V63.787l32.709,0.001c1.39,0,2.515-1.125,2.515-2.516l-0.001-22.541c0.001-1.389-1.124-2.515-2.516-2.516l-32.706,0V24.029   c0-0.94-0.53-1.803-1.367-2.237C50.911,21.359,49.9,21.434,49.132,21.984z" />
    </g>
  </svg>
)

const RightIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    className="fill-current text-blue-600 hover:text-blue-500 transition-colors duration-150 ease-in-out"
  >
    <g>
      <path d="M50.868,78.016l36.418-26.055c0.66-0.471,1.049-1.233,1.051-2.043c0-0.006,0-0.006,0-0.006   c-0.002-0.815-0.396-1.575-1.059-2.048L50.86,21.977c-0.767-0.546-1.776-0.616-2.612-0.183c-0.835,0.426-1.361,1.292-1.361,2.236   v12.183l-32.709-0.001c-1.39,0-2.515,1.125-2.515,2.516l0.001,22.541c-0.001,1.389,1.124,2.515,2.516,2.516l32.706,0v12.187   c0,0.94,0.53,1.803,1.366,2.237C49.089,78.641,50.1,78.567,50.868,78.016z" />
    </g>
  </svg>
)

export { LeftIcon, RightIcon }
