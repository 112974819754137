import React from "react"
import { Helmet } from "react-helmet"
import useSiteMetadata from "../hooks/useSiteMetadata"

const Head = () => {
  const {
    site: {
      siteMetadata: { title, description, siteLanguage },
    },
  } = useSiteMetadata()
  return (
    <Helmet>
      <html lang={siteLanguage} />
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  )
}

export default Head
