import React from "react"
import useSiteMetadata from "../hooks/useSiteMetadata"

const Footer = () => {
  const {
    site: {
      siteMetadata: { siteName, currentYear },
    },
  } = useSiteMetadata()
  return (
    <footer>
      <div className="text-md text-center">
        {siteName} © {currentYear}
      </div>
    </footer>
  )
}

export default Footer
