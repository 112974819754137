import React from "react"
import ContactForm from "./ContactForm"
import List from "./List"
import HeroText from "./HeroText"

const listItems = [
  "Local Highly Experienced Certified Public Accountants",
  "Avoid, Fines, Fees and Penalties",
  "Top Quality Tax Preparation and Customer Service",
]

const textItems = [
  "WE CARE and are here to help you succeed!",
  "At many large tax firms, you are just a number, your taxes are done by people with less than 6 months of experience. We are small enough to take care of each and every one of our clients, we hire only the best and require 5+ years of experience with licensed professionals.",
  "At San Diego Tax Services your taxes are reviewed by top-level CPAs with 100+ years of combined experience!",
  "All work is done remotely.",
  "You will rest easy knowing that you have lowered your tax burden by maximum amount allowed by law.",
]

const Hero = () => {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex flex-col md:w-1/2">
        <div className="text-3xl text-center my-4">Taxes Done Right!</div>

        <List items={listItems} />

        {textItems.map((item, index) => (
          <HeroText text={item} key={index} />
        ))}
      </div>

      <ContactForm />
    </div>
  )
}

export default Hero
