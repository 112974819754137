import React from "react"

const Review = ({ text, name, img }) => {
  return (
    <div className="flex flex-col text-center max-w-md mx-auto">
      <p className="text-md mb-2">{text}</p>
      <img
        src={img}
        alt={`${name}'s avatar`}
        className="w-24 h-24 rounded-full mx-auto mb-2"
      />
      <div className="text-md mx-auto mb-2">{name}</div>
    </div>
  )
}

export default Review
