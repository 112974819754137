import React from "react"

const ContactFormInput = ({
  label,
  placeholder,
  name,
  onChange,
  type = "text",
  required = false,
}) => {
  return (
    <label className="flex flex-col text-md mb-2">
      {label}
      <input
        placeholder={placeholder}
        type={type}
        name={name}
        required={required}
        className="border-2 rounded p-1"
        onChange={onChange}
      />
    </label>
  )
}

export default ContactFormInput
