import React from "react"
import useSiteMetadata from "../hooks/useSiteMetadata"

const Header = () => {
  const {
    site: {
      siteMetadata: { phoneNumber, phoneNumberDisplay, logo, siteName },
    },
  } = useSiteMetadata()
  return (
    <header className="flex justify-between items-center">
      <img src={logo} alt={`${siteName} logo`} className="w-6 h-8" />
      <div className="text-xl md:text-3xl">
        Call us now{" "}
        <a
          href={`tel:${phoneNumber}`}
          className="text-blue-600 hover:text-blue-500 transition-colors duration-150 ease-in-out"
        >
          {phoneNumberDisplay}
        </a>
      </div>
    </header>
  )
}

export default Header
