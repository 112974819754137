import React from "react"
import Head from "./Head"
import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ children }) => {
  return (
    <div className="md:container md:mx-auto px-6 py-3">
      <Head />
      <Header />
      {children}
      <Footer />
    </div>
  )
}

export default Layout
