import React from "react"
import Carousel from "nuka-carousel"
import Review from "./Review"
import { LeftIcon, RightIcon } from "./Icons"

const Reviews = ({ reviews }) => {
  return (
    <section>
      <div className="text-3xl text-center my-4">The Best Experience Ever</div>

      <Carousel
        autoplay={true}
        wrapAround={true}
        autoplayInterval={5000}
        renderBottomCenterControls={() => null}
        transitionMode="scroll"
        renderCenterLeftControls={({ previousSlide }) => (
          <div
            onClick={previousSlide}
            onKeyDown={previousSlide}
            className="hidden md:flex justify-center items-center w-12 h-12"
            role="button"
            tabIndex={0}
          >
            <LeftIcon />
          </div>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <div
            onClick={nextSlide}
            onKeyDown={nextSlide}
            className="hidden md:flex justify-center items-center w-12 h-12"
            role="button"
            tabIndex={0}
          >
            <RightIcon />
          </div>
        )}
      >
        {reviews.map(({ text, name, img }, index) => (
          <Review key={index} text={text} img={img} name={name} />
        ))}
      </Carousel>
    </section>
  )
}

export default Reviews
