import React from "react"
import useSiteMetadata from "../hooks/useSiteMetadata"

const PhoneCTA = () => {
  const {
    site: {
      siteMetadata: { phoneNumber, phoneNumberDisplay },
    },
  } = useSiteMetadata()
  return (
    <div className="text-xl md:text-3xl text-center my-4">
      <span>Call us now </span>
      <a
        href={`tel:${phoneNumber}`}
        className="text-blue-600 hover:text-blue-500 transition-colors duration-150 ease-in-out"
      >
        {phoneNumberDisplay}
      </a>
    </div>
  )
}

export default PhoneCTA
