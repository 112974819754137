import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Reviews from "../components/Reviews"
import PhoneCTA from "../components/PhoneCTA"
import useSiteMetadata from "../hooks/useSiteMetadata"

const HomePage = () => {
  const {
    site: {
      siteMetadata: { reviews },
    },
  } = useSiteMetadata()

  return (
    <Layout>
      <Hero />
      <Reviews reviews={reviews} />
      <PhoneCTA />
    </Layout>
  )
}

export default HomePage
