import React from "react"

const ContactFormTextArea = ({ label, placeholder, name, onChange }) => {
  return (
    <label className="flex flex-col text-md">
      {label}
      <textarea
        name={name}
        placeholder={placeholder}
        className="border-2 rounded p-1"
        onChange={onChange}
      ></textarea>
    </label>
  )
}

export default ContactFormTextArea
